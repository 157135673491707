import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import "./ContactForm.css";
import Header from "../../Header";
import Paragraph from "../../Paragraph";
import ClaimsInput from "../ClaimsPage/ClaimsInput";
import Button from "../../Button";
import axios from "axios";

const ContactForm = () => {

    const [states, setStates] = useState({
        isSubmitted: false,
        status: "",
        inputs: {},
        essentialFields: ["Name", "Email", "Phone", "Address", "Zip", "Message"]
    });

    const validateForm = () => {
        for (let field of states.essentialFields) {
            if (!states.inputs[field] || states.inputs[field] === '') {
                return false;
            }
        }
        return true;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStates(prevState => ({
            ...prevState,
            inputs: {
                ...prevState.inputs,
                [name]: value
            }
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            setStates(prev => ({...prev, status: "Udfyld venligst alle felter"}));
            return;
        }

        setStates(prev => ({...prev, isSubmitted: true, status: "Sending..."}));
        const form = e.target.closest("form");
        
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            withCredentials: false
        }

        let requestData = new FormData(form);

        //Send the formdata to the backend, but for now just make a settimout to simulate a request
        axios.post(`${process.env.REACT_APP_API_URL}/v1/contact/create-submission`, requestData, config)
            .then(res => {
                setStates(prevState => ({
                    ...prevState,
                    status: "Success!"
                }));

            }).catch(err => {
                setStates(prevState => ({
                    ...prevState,
                    status: "Noget gik galt"
                }));
            }
        );
    }


    return (
        <Container className="my-md-5 my-2 custom-container">
            <Row className="pt-3">
                <Col>
                    <div className="contact-form-container p-md-5 py-5 px-4">
                        <Row>
                            <Col>
                                <Header size="h4" className="fw-bold">
                                    {/* Let us know how we can help */}
                                    Hvordan kan vi hjælpe?
                                </Header>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <form className="claims-inner-form my-md-5 my-4 p-md-4 p-3" onSubmit={handleSubmit}>
                                    <div>
                                        <Header size="h3" className="my-4">Person Oplysninger</Header>
                                        
                                        <ClaimsInput essential name="Fulde Navn" inputName="Name" placeholder="Jens Jensen" submission={handleChange}/>
                                        <ClaimsInput essential name="Email" inputName="Email" placeholder="Jens.jensen@gmail.com" submission={handleChange}/>
                                        <ClaimsInput essential name="Telefonnummer" inputName="Phone" placeholder="(+45) 31424729" submission={handleChange}/>
                                        <ClaimsInput essential name="Adresse" inputName="Address" placeholder="Østerbrogade 21, 2.tv" submission={handleChange}/>
                                        <ClaimsInput essential name="By" inputName="City" placeholder="København" submission={handleChange}/>
                                        <ClaimsInput essential name="Postnummer" inputName="Zip" placeholder="2100" submission={handleChange}/>
                                    </div>

                                    <div className="mt-5 pt-2">
                                        <Header size="h3" className="my-4">Efterspørgsel</Header>
                                        <ClaimsInput type="textarea" minHeight={"120px"} essential name="Hvad drejer henvendelsen sig om?" inputName="Message" placeholder="Tekst..." submission={handleChange}/>
                                    </div>

                                    <Button disabled={states.isSubmitted}>Send</Button><p className="mt-3 px-md-5 d-inline-block">{ states.status }</p>
                                </form>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="py-3">
                                <div>
                                    <Header size="h3" className="fw-semibold py-4">
                                        {/* Contact for shoppers: */}
                                        Kontakt for shoppere:
                                    </Header>
                                    <Paragraph size="p3" className="fw-light contact-form-paragraph">Mail: Shopper@franklyinsure.com</Paragraph>
                                    {/* <Paragraph size="p3" className="fw-light contact-form-paragraph">Phone: +45 2548 8314</Paragraph> */}
                                    <Paragraph size="p3" className="fw-light contact-form-paragraph">Telefon: +45 2548 8314</Paragraph>
                                </div>
                                <div>
                                    <Header size="h3" className="fw-semibold py-4">
                                        {/* Contact for business: */}
                                        Kontakt for butikker:
                                    </Header>
                                    <Paragraph size="p3" className="fw-light contact-form-paragraph">Mail: Merchant@franklyinsure.com</Paragraph>
                                    {/* <Paragraph size="p3" className="fw-light contact-form-paragraph">Phone: +45 3142 4749</Paragraph> */}
                                    <Paragraph size="p3" className="fw-light contact-form-paragraph">Telefon: +45 3142 4749</Paragraph>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            
        </Container>
    );
}

export default ContactForm;