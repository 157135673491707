import Paragraph from "./Paragraph";
import './StepCard.css';
import Wave from '../img/Wave.svg';

const StepCard = ({
  number,
  description,
  children,
  className,
  bottom="0px"
}) => {
  return ( 
    <div className={`step-card frankly-card ${className || ""}`}>
      <div className="step-card-header d-inline-flex flex-column mx-auto">
        <Paragraph fontSize="20px" className="step-card-number fw-bold">Step {number}</Paragraph>
        <img src={Wave} alt="Wave" className="step-card-wave"/>
      </div>
      <div className="step-card-description">
        <Paragraph fontSize="20px" className="step-card-description-text fw-normal">{description}</Paragraph>
      </div>
      <div className="step-card-content">
        {children}
      </div>
    </div>
   );
}
 
export default StepCard;