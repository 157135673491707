import './Collage.css'
import SectionWrapper from "../SectionWrapper";
import { Row, Col, Container } from "react-bootstrap";
import Header from '../Header';
import Paragraph from '../Paragraph';
import CollageItem from '../CollageItem';
import collage1 from '../../img/collage1.webp';
import collage2 from '../../img/collage2.webp';
import collage3 from '../../img/collage3.webp';
import collage4 from '../../img/collage4.webp';
import collage5 from '../../img/collage5.webp';
import collage6 from '../../img/collage6.webp';


const Collage = () => {
  
  // const collageItems = [
  //   [
  //     {image: collage1, title: 'Appliance'},
  //     {image: collage2, title: 'Beauty'},
  //   ],
  //   [
  //     {image: collage3, title: 'Electronics'},
  //     {image: collage4, title: 'Mobility'},
  //   ],
  //   [
  //     {image: collage5, title: 'Apparel'},
  //     {image: collage6, title: 'Home Decor'},
  //   ],
  // ]
  const collageItems = [
    [
      {image: collage1, title: 'Husholdning'},
      {image: collage2, title: 'Wellness'},
    ],
    [
      {image: collage3, title: 'Elektronik'},
      {image: collage4, title: 'Transport'},
    ],
    [
      {image: collage5, title: 'Mode'},
      {image: collage6, title: 'Bolig'},
    ],
  ]
  
  return (
    <Container className='custom-container pt-3 mt-7'>
      <Row className=''>
        <Header size='h4' color="primary" className='collage-header pt-5 fw-light pb-4 p-md-0'>

          {/* Add protection <span className="fw-bold"> when you shop</span> */}
          Tilføj tryghed <span className="fw-bold"> når du shopper</span>

        </Header>

        {/* Desktop */}
        <Col className='d-none d-md-block'>
          <div className="d-flex justify-content-center pt-5">
            <CollageItem img image={collageItems[0][0].image} title={collageItems[0][0].title}/>
            <CollageItem className="middle" img image={collageItems[0][1].image} title={collageItems[0][1].title}/>
            <CollageItem image={collageItems[1][0].image} title={collageItems[1][0].title}/>
          </div>
          <div className="d-flex justify-content-center">
            <CollageItem image={collageItems[1][1].image} title={collageItems[1][1].title}/>
            <CollageItem className="middle" image={collageItems[2][0].image} title={collageItems[2][0].title}/>
            <CollageItem image={collageItems[2][1].image} title={collageItems[2][1].title}/>
          </div>
        </Col>

        {/* Mobile */}
        <Col xs={6} className='d-block d-md-none ps-2 pe-1 p-0 '>
          <CollageItem imgClass="w-100 collage-mobile-image" shadow={false} image={collageItems[0][0].image} title={collageItems[0][0].title}/>
          <CollageItem imgClass="w-100 collage-mobile-image" shadow={false} image={collageItems[1][0].image} title={collageItems[1][0].title}/>
          <CollageItem imgClass="w-100 collage-mobile-image" shadow={false} image={collageItems[2][0].image} title={collageItems[2][0].title}/>
        </Col>
        <Col xs={6} className='d-block d-md-none pe-2 ps-1 p-0 pt-5'>
          <CollageItem imgClass="w-100 collage-mobile-image" shadow={false} image={collageItems[0][1].image} title={collageItems[0][1].title}/>
          <CollageItem imgClass="w-100 collage-mobile-image" shadow={false} image={collageItems[1][1].image} title={collageItems[1][1].title}/>
          <CollageItem imgClass="w-100 collage-mobile-image" shadow={false} image={collageItems[2][1].image} title={collageItems[2][1].title}/>
        </Col>
      </Row>
    </Container>

  );
}

export default Collage;