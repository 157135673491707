import SectionWrapper from "../SectionWrapper";
import { Container, Row, Col } from "react-bootstrap";
import { RiFacebookFill, RiInstagramLine, RiLinkedinBoxFill } from "react-icons/ri";
import VectorLogo from "../../img/NavLogo.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import "./Footer.css";
import Header from "../Header";
import Paragraph from "../Paragraph";
import MailIcon from "../../img/MailIcon.svg";
import PersonIcon from "../../img/PersonIcon.svg";
import { Link } from "react-router-dom";


const Footer = () => {
  return ( 
    <Container className="custom-container mb-5 mt-6">
      <Row>
        <Col xs={12} sm={12} md={5} lg={4}  className="d-flex flex-column justify-content-start">
          <div className="footer-logo-container d-flex align-items-center">
            <div className="footer-image-container d-flex justify-content-center align-items-center">
              <LazyLoadImage
                height="37px"
                alt="BILLEDE"
                src={VectorLogo}
                effect="black-and-white"
                className=""
              />
            </div>
            <Header size="h4" color="primary" className="pt-2 ps-2 fw-light footer-header">
              <span className="fw-semibold">Frankly</span> Insure
            </Header>
          </div>
          <div className="footer-left-text ps-3">
            <Paragraph size="p3" className="pt-4 mb-0 fw-light footer-paragraph">
              
              {/* We're here for you. */}
              Vi er her for dig.

            </Paragraph>
            <Paragraph size="p3" className="pt-0 fw-light footer-paragraph">
              
              {/* See expected response time. */}
              Se forventet svartid.


            </Paragraph>
            <table className="footer-table fw-normal ">
              <tr>
                {/* <td>Mon - Thu</td> */}
                <td>Man - Tor</td>
                <td className="ps-4">8:00 - 17:00</td>
              </tr>
              <tr className="pt-2">
                {/* <td>Friday</td> */}
                <td>Fredag</td>
                <td className="ps-4">8:00 - 15:00</td>
              </tr>
            </table>
            <div className="">
              <div className="d-flex pt-4">
                <LazyLoadImage
                  width="20"
                  height="20"
                  alt="BILLEDE"
                  src={MailIcon}
                  effect="black-and-white"
                  className="pb-1"
                />
                <Paragraph size="p3" className="ps-2 mb-0 fw-normal  footer-paragraph">
                  Shopper@franklyinsure.com 
                </Paragraph>
              </div>
              <div className="d-flex pt-2">
                <LazyLoadImage
                  width="20"
                  height="20"
                  alt="BILLEDE"
                  src={MailIcon}
                  effect="black-and-white"
                  className="pb-1"
                />
                <Paragraph size="p3" className="ps-2 mb-0 fw-normal footer-paragraph">
                  Merchant@franklyinsure.com
                </Paragraph>
              </div>
              <div className="d-flex pt-2">
                <LazyLoadImage
                  width="20"
                  height="20"
                  alt="BILLEDE"
                  src={PersonIcon}
                  effect="black-and-white"
                  className="pb-1"
                />
                <Paragraph size="p3" className="ps-2 mb-0 fw-normal footer-paragraph">
                +45 70 60 44 40
                </Paragraph>
              </div>
            </div>
          </div>
        </Col>
        <Col className="d-flex flex-column justify-content-between ">
          <div className="footer-links">
            <div className="footer-links-col">
              <Header size="h2" color="primary" className="bold footer-links-header">
                
                {/* Shop with Frankly */}
                Shop med Frankly

              </Header>
              <Paragraph size="p2" className="pt-4 mb-0 fw-light footer-paragraph">
                <Link to="/">For shoppers</Link>
              </Paragraph>
              <Paragraph size="p2" className="pt-2 mb-0 fw-light footer-paragraph">
                
                {/* <a href="/business">For businesses</a> */}
                <Link to="/business">For butikker</Link>

              </Paragraph>
            </div>
            <div className="footer-links-col">
              <Header size="h2" color="primary" className="bold footer-links-header">
                Support
              </Header>
              <Paragraph size="p2" className="pt-4 mb-0 fw-light footer-paragraph">
                {/* <a href="/contact">Contact us</a> */}
                <Link to="/kontakt">Kontakt os</Link>
              </Paragraph>
              <Paragraph size="p2" className="pt-2 mb-0 fw-light footer-paragraph">
                {/* <a href="/claims">Claim</a> */}
                <Link to="/anmeld-skade">Anmeld skade</Link>
              </Paragraph>
              <Paragraph size="p2" className="pt-2 mb-0 fw-light footer-paragraph d-none">
                <Link to="/#">FAQ</Link>
              </Paragraph>
            </div>
            <div className="footer-links-col">
              <Header size="h2" color="primary" className="bold footer-links-header">
                Legal Stuff
              </Header>
              <Paragraph size="p2" className="pt-4 mb-0 fw-light footer-paragraph">

                {/* <a href="/terms">Terms of service</a> */}
                <Link to="/vilk%C3%A5r">Vilkår</Link>

              </Paragraph>
              <Paragraph size="p2" className="pt-2 mb-0 fw-light footer-paragraph">
                {/* <a href="/conditions">Conditions</a> */}
                <Link to="/betingelser">Betingelser</Link>
              </Paragraph>
              <Paragraph size="p2" className="pt-2 mb-0 fw-light footer-paragraph d-none">
                <Link to="/#">Privacy policy</Link>
              </Paragraph>
            </div>
            <div className="footer-links-col d-none">
              <Header size="h2" color="primary" className="bold footer-links-header">
                About
              </Header>
              <Paragraph size="p2" className="pt-4 mb-0 fw-light footer-paragraph">
                <Link to="/#">About Us</Link>
              </Paragraph>
              <Paragraph size="p2" className="pt-2 mb-0 fw-light footer-paragraph">
                <Link to="/#">Team</Link>
              </Paragraph>
            </div>
          </div>
          <div className="footer-icons">
            <a target="_blank" href="https://www.facebook.com/Frankly.Insure">
              <RiFacebookFill/>
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/frankly-ins/">
              <RiLinkedinBoxFill/>  
            </a>
            <a target="_blank" href="https://www.instagram.com/franklyinsure/">
              <RiInstagramLine/>
            </a>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="footer-divider pt-4"/>
        </Col>
      </Row>
      <Row className=" ">
      <Col  xl={{span: 6, order: 2}} md={{span: 12, order: 2}}  className="pb-2 pt-xl-0 justify-content-xl-end justify-content-start d-flex">
          <Paragraph size="p2" className="mb-0 paragraph-muted">
            Frankly Insure ApS is registered with the Danish Financial Supervisory Authority.
          </Paragraph>
        </Col>
        <Col className="" xl={{span: 6, order: 1}} md={{span: 12, order: 1}} >
          <div className="d-md-flex pb-2 footer-bottom-text">
            <Paragraph size="p2" className="mb-0 paragraph-muted">
              Valhøjvej 18
            </Paragraph>
            <Paragraph size="p2" className="ps-md-3 ps-xxl-3 mb-0 paragraph-muted">
              2500 København 
            </Paragraph>
            <Paragraph size="p2" className="ps-md-3 ps-xxl-3 mb-0 paragraph-muted">
              +45 70 60 44 40
            </Paragraph>
            <Paragraph size="p2" className="ps-md-3 ps-xxl-3 mb-0 paragraph-muted">
              CVR: 42626449
            </Paragraph>
          </div>
        </Col>
  
      </Row>
    </Container>
   );
}
 
export default Footer;
