import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import SectionWrapper from "../SectionWrapper";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import MaxWidthWrap from "../MaxWidthWrap";
import './Partners.css'

import Aventures from '../../img/Aventures.png';
import Antler from '../../img/Antler.png';
import AON from '../../img/AON.png';
import Globus from '../../img/Globus.png';
import Wakam from '../../img/Wakam.png';
import { Container, Row, Col } from 'react-bootstrap'
import { useEffect, useState, useRef } from 'react';

const Partners = () => {
  const [images, setImages] = useState([
    Aventures,
    Antler,
    AON,
    Globus,
    Wakam,
  ]);

  //The number of images-sets to display at once
  const [imageSets , setImageSets] = useState([1,2,3,4,5]);

  const scrollerRef = useRef(null);

  /*
  useEffect(() => {
    const scroller = scrollerRef.current;
    let scrollLeft = 0;
    const scrollSpeed = 2; // Adjust this for speed (2 seems about right)

    
    const scroll = () => {
        const imagesSetWidth = scroller.querySelector('.images-set').offsetWidth;
        scrollLeft += scrollSpeed;
        scroller.scrollLeft = scrollLeft;

        if (scrollLeft >= imagesSetWidth) {
            // Reset scroll position by width of one set (to give the effect of continuity)
            scroller.scrollLeft = scrollLeft - imagesSetWidth;
            scrollLeft = scroller.scrollLeft;

            // Add a new images-set at the end and remove the first one
            setImageSets(prevSets => [...prevSets.slice(1), prevSets[prevSets.length - 1] + 1]);
        }
    };
    scroll();
  }, []);
  */


  return ( 
    <Container className='custom-container my-lg-5'>
      <Row className='pt-5'>
        {/* Desktop */}
        <Col className='pt-4 d-none d-xl-none'>
          <MaxWidthWrap center width="775px">
            <div className='pt-5 partners-container d-flex justify-content-center justify-content-sm-between align-items-center'>
              <LazyLoadImage
                height="60"
                alt="Aventures.png"
                src={Aventures}
                effect="black-and-white"
                className=''
              />
              <LazyLoadImage
                height="60"
                alt="Antler.png"
                src={Antler}
                effect="black-and-white"
                className=''
              />
              <LazyLoadImage
                height="40"
                alt="Wakam.png"
                src={Wakam}
                effect="black-and-white"
                className=''
              />
              <LazyLoadImage
                height="60"
                alt="Globus.png"
                src={Globus}
                effect="black-and-white"
                className=''
              />
              <LazyLoadImage
                height="40"
                alt="AON.png"
                src={AON}
                effect="black-and-white"
                className=''
              />
            </div>
          </MaxWidthWrap>
        </Col>

        {/* Mobile */}
        <Col className='pt-0 d-block d-xl-block'>
        <div className="scroll-container" ref={scrollerRef}>
          {imageSets.map(setIndex => (
            <div className="images-set" key={setIndex}>
              {/* Your list of images */}
              {images.map((src, index) => (
                <img key={index} src={src} alt={`carousel-item-${index}`} />
              ))}
            </div>
            ))}
        </div>
        </Col>
      </Row>
    </Container>
   );
}
 
export default Partners;