import "./ContactHero.css";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Col, Container, Row } from "react-bootstrap";

import ContactHeroImg from "../../../img/ContactHeroImg.png"
import Header from "../../Header";
import Paragraph from "../../Paragraph";
import MaxWidthWrap from "../../MaxWidthWrap";

const ContactHero = () => {
    return (
        <Container className="my-md-5 my-2 custom-container">
            <Row className="pt-3">
                <Col lg={6}>
                    <MaxWidthWrap width={561} className="m-auto">
                        {/* <Header size="h5" color="primary" className="hero-header header-component fw-bold mb-5 mt-5">Need help? We are available 24/7</Header> */}
                        <Header size="h5" color="primary" className="hero-header header-component fw-light mb-5 mt-5"><span className="fw-bold">Kontakt og support.<br/></span> Vi står klar 24/7</Header>
                        <Paragraph size="p3" className="fw-light">
                            {/* Simply fill out the form below and explain to us what you need help with.Our support team will get back to you as soon as possible. */}
                            Udfyld venligst vores kontaktformular, og vores<br/> supportteam vil vende tilbage til dig snarest.
                        </Paragraph>
                        <Paragraph size="p3" className="fw-light">
                            {/* For time-sensitive inquiries you're welcome to call our support. */}
                            Skulle du have akutte spørgsmål, er du altid<br/> velkommen til at ringe til os.
                        </Paragraph>
                    </MaxWidthWrap>
                </Col>
                <Col lg={6} className="d-flex justify-content-center justify-content-lg-right">
                    <div className="d-table contact-hero-img">
                        <LazyLoadImage
                            alt="Contact image"
                            src={ContactHeroImg}
                            effect="black-and-white"
                            className="contact-header-img"
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default ContactHero;