import { LazyLoadImage } from "react-lazy-load-image-component";
import "./ToggleInnerCard.css"
import Header from "./Header";

import PDFDownload from "../img/pdfDownload.svg";

const ToggleInnerCard = ({
    heading,
    paragraph = "",
    pdf
}) => {
    if(pdf){
        return (
            <a href={pdf}
                target="_blank"
                className="toggle-inner-card d-flex justify-content-between p-3 m-md-3 my-2"
            >
                <Header size="h2" className="fw-medium">{heading}<span className="fw-light">&nbsp;{ paragraph}</span></Header>
                <LazyLoadImage
                    alt="PDF download"
                    src={PDFDownload}
                    className="pdf-download-img"
                />
            </a>
        );
    } else return null;
}

export default ToggleInnerCard;