import './Different.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import { Row, Col } from 'react-bootstrap';
import SectionWrapper from "../SectionWrapper";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import MaxWidthWrap from "../MaxWidthWrap";
import Paragraph from '../Paragraph';
import Header from '../Header';
import Container from 'react-bootstrap/Container';

import PhoneBread from '../../img/PhoneBread.png';
import Thumbs from '../../img/Thumbs.webp';

const Different = () => {
  return ( 
    <Container className='custom-container my-lg-5 pt-lg-5 different-container'>
      <Header size="h5" color="primary" className="text-center fw-light pt-5 mt-5 mb-0 ">
        {/* Frankly speaking, <span className="fw-bold">we're flipping the script</span> */}
        <span className="fw-bold">Frankly Insure.</span> Hvad er forskellen?
      </Header>
      <Row className='pt-4 pt-sm-6 position-static'>
        <Col xl={6} className='my-xl-7'>
            <Paragraph className='text-center text-xl-start fw-light' size='p4'> 
              {/* <span className='fw-bold'>The add-on insurance market is a <br className='d-none d-xl-block'/> bit, well yeah.</span> We decided to see if <br className='d-none d-xl-block'/> we could do better. */}
              <span className='fw-bold'>Traditionel produktforsikring er lidt, <br className='d-none d-xl-block'/> ja, du ved.</span> Vi besluttede os for at se,<br className='d-none d-xl-block'/> om vi kunne gøre det bedre.
            </Paragraph>
            <Paragraph className='text-center text-xl-start fw-light m-100 d-none d-xl-block' size='p4'> 
              {/* <span className='fw-bold'>We want to be the preferred insurance solution for shops and shoppers. <br className='d-none d-xl-block'/> </span> And we want you to understand why. */}
              <span className='fw-bold'>Vi vil være den foretrukne løsning for både shops og shoppere. <br className='d-none d-xl-block'/> </span> Og vil have, at du forstår hvorfor det er interessant for dig.
            </Paragraph>
        </Col>
        <Col xl={6} className=''>
          <div className="d-flex justify-content-center justify-content-xl-end sticky-right">
            <LazyLoadImage
              alt="BILLEDE"
              src={Thumbs}
              effect="black-and-white"
              className='different-image frankly-card w-100'
              />
          </div>
          <Paragraph className='text-center text-xl-start  m-100 d-xl-none pt-3' size='p4'> 
            {/* <span className='fw-bold'>We want to be the preferred insurance solution for shops and shoppers. <br className='d-none d-xl-block'/> </span> And we want you to understand why. */}
            <span className='fw-bold'>Vi vil være den foretrukne løsning for både shops og shoppere. <br className='d-none d-xl-block'/> </span> Og vil have, at du forstår hvorfor det er interessant for dig.
          </Paragraph>
        </Col>
      </Row>
    </Container>
   );
}
 
export default Different;