import { Col, Container, Row } from "react-bootstrap";
import "./ConditionExpandableSection.css";
import Header from "../../Header";

import ToggleCard from "../../ToggleCard";
import { useState } from "react";
// import Wording from "../../../pdf/Electronics/Policy wording_Wakam_Frankly_ADT.pdf"
// import IPID from "../../../pdf/Electronics/IPID_Wakam_Frankly_ADT.pdf"

const ConditionExpandableSection = () => {
    const toggleCards = [
        {heading: "Elektronik", innerCards: [
            {heading: "Frankly Insure Tryghedspakke", paragraph:"(Police)", pdf: "/pdf/policy/electronics"},
            {heading: "IPID", paragraph: "", pdf: "/pdf/ipid"},
        ]}
    ];
    const [ openIndex, setOpenIndex] = useState(0);
    return (
        <Container className='custom-container my-5'>
            <Row className="my-5">
                <Col>
                    <Header fontSize="50px" color="primary" className="py-md-4 fw-semibold m-auto">
                        {/* Conditions and policies */}
                        Betingelser
                    </Header>
                </Col>
            </Row>
            <Row className="my-5">
                <Col className="terms-container px-3 px-md-5 pb-5">
                {toggleCards.map((toggleCard, index) => (
                <ToggleCard 
                    heading={toggleCard.heading} 
                    innerCards={toggleCard.innerCards} 
                    key={index} 
                    open={openIndex === index} 
                    setOpenIndex={() => setOpenIndex(openIndex === index ? -1 : index)} // Updated here
                />
                ))}
                </Col>
            </Row>
        </Container>
    );
}

export default ConditionExpandableSection;