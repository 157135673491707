import ClaimsForm from "../page-components/ClaimsPage/ClaimsForm";
import ClaimsHero from "../page-components/ClaimsPage/ClaimsHero";
import Footer from "../page-components/Footer";
import NavBar from "../page-components/NavBar";

const Claims = () => {
    return (
        <>
            <NavBar
                navArray={[
                {name: "Hjem", link: "/", active: false},
                {name: "For butikker", link: "/business", active: false},
                ]}
            />

            <ClaimsHero/>
            
            <ClaimsForm/>

            <Footer/>
        </>
    );
}

export default Claims;