import { LazyLoadImage } from "react-lazy-load-image-component";
import Header from "./Header";
import "./ToggleCard.css";

import ArrowOpen from "../img/ArrowOpen.svg"
import ArrowClosed from "../img/ArrowClosed.svg"
import ToggleInnerCard from "./ToggleInnerCard";

const ToggleCard = ({
    heading,
    innerCards,
    open,
    setOpenIndex
}) => {
  return (
    <>
      <div className={`toggle-card py-md-4 py-3 px-md-4 px-2 my-4 ${open ? 'toggle-card-expanded' : ''}`} onClick={setOpenIndex}>
        <div className="d-flex justify-content-between">
          <Header size="h3" className="fw-semibold">{heading}</Header>
          <LazyLoadImage alt="Arrow" src={open ? ArrowOpen : ArrowClosed} />
        </div>
        {open && <div className="toggle-card-expandable-content">
          {innerCards.map((innerCard, index) => (
            <ToggleInnerCard heading={innerCard.heading} paragraph={innerCard.paragraph} key={index} pdf={innerCard.pdf} />
          ))}
        </div>}
      </div>
    </>
  );
}

export default ToggleCard;