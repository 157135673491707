import { Col, Container, Row } from "react-bootstrap";
import "./ClaimsForm.css";
import Header from "../../Header";
import ClaimsInput from "./ClaimsInput";
import Button from "../../Button";
import { useState } from "react";
import axios from 'axios';

const ClaimsForm = () => {
    //Satet to manage the form fields, status message and more
    const [states, setStates] = useState({
        receiptOpen: false,
        homeAddresse: false,
        fileInputs: {},
        inputs: {},
        status: '',
        isSubmitted: false,
        essentialFields: ['Name', 'Email', 'Phone', 'Address', 'City', 'Zip', 'Explanation', 'Model', 'TimeOfIncident', 'PolicyNumber', 'IncidentAddress', 'IncidentCity', 'IncidentZip']
    });

    // Function to toggle boolean state values and handle change for sliders
    const toggleState = (stateName, e) => {
        setStates(prev => {
            // Toggle the state
            const updatedState = { ...prev, [stateName]: !prev[stateName] };
            
            // Update essential fields
            if (stateName === 'homeAddresse' && updatedState.homeAddresse) {
                updatedState.essentialFields = updatedState.essentialFields.filter(field => !['IncidentAddress', 'IncidentCity', 'IncidentZip'].includes(field));
            } else if (stateName === 'homeAddresse' && !updatedState.homeAddresse) {
                updatedState.essentialFields = updatedState.essentialFields.concat(['IncidentAddress', 'IncidentCity', 'IncidentZip']);
            }
    
            if (stateName === 'receiptOpen' && updatedState.receiptOpen) {
                updatedState.essentialFields = updatedState.essentialFields.concat(['ProductPrice', 'ProductPlaceOfPurchase', 'ProductTimeOfPurchase', 'ProductDescription']);
            } else if (stateName === 'receiptOpen' && !updatedState.receiptOpen) {
                updatedState.essentialFields = updatedState.essentialFields.filter(field => !['ProductPrice', 'ProductPlaceOfPurchase', 'ProductTimeOfPurchase', 'ProductDescription'].includes(field));
            }
            console.log(updatedState.essentialFields);
    
            return updatedState;
        });
    
        handleChange(e);
    }
    

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        if (files) {
            // Handle file input change
            setStates(prev => ({
                ...prev,
                fileInputs: {
                    ...prev.fileInputs,
                    [name]: files
                }
            }));
        } else {
            // Handle standard input change
            setStates(prev => ({
                ...prev,
                inputs: {
                    ...prev.inputs,
                    [name]: value
                }
            }));
        }
    }
    

    const validateForm = () => {
        for (let field of states.essentialFields) {
            if (!states.inputs[field] || states.inputs[field] === '') {
                return false;
            }
        }
    
        return true;
    }
    

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!validateForm()) {
            setStates(prev => ({ ...prev, status: 'Udfyld venligst alle felter markeret med stjerne (*).' }));
            return;
        }
        setStates(prev => ({ ...prev, isSubmitted: true }));
        const form = e.target.closest('form');

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
            },
            withCredentials: false
        }

        const requestData = new FormData();

        for (let key in states.fileInputs) {
            if (states.fileInputs[key]) {
                for (let i = 0; i < states.fileInputs[key].length; i++) {
                    requestData.append(key, states.fileInputs[key][i]);
                }
            }
        }

        requestData.append("json", JSON.stringify(states.inputs));

        axios.post(`${process.env.REACT_APP_API_URL}/v1/claims/create-claim`, requestData, config)
            .then(res => {
                console.log(res);
                setStates(prev => ({
                    ...prev,
                    status: <span className="status-green">Tak for at skrive dig op. Dine informationer er nu gemt.</span>
                }));
                form.reset();
            }).catch(err => {
                console.log(err);
                setStates(prev => ({
                    ...prev,
                    status: 'Noget gik galt. Prøv igen.',
                    isSubmitted: false
                }));
            })
    }

    return (
        <Container className="my-md-5 my-2 custom-container">
            <Row className="pt-3">
                <Col>
                    <div className="claims-form py-md-5 px-md-4 px-2 py-4">
                        <Row>
                            <Col>
                                <Header size="h4" className="fw-bold">
                                    Anmeld din skade her
                                </Header>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <form className="claims-inner-form my-md-5 my-4 p-md-4 p-3" onSubmit={handleSubmit}>

                                    <Header size="h3" className="my-4">Person Oplysninger</Header>

                                    <ClaimsInput essential name="Fulde Navn" inputName="Name" placeholder="Jens Jensen" submission={handleChange}/>
                                    <ClaimsInput essential name="Email" inputName="Email" placeholder="Jens_jensen@gmail.com" submission={handleChange}/>
                                    <ClaimsInput essential name="Telefonnummer" inputName="Phone" placeholder="(+45) 31424729" submission={handleChange}/>
                                    <ClaimsInput essential name="Adresse" inputName="Address" placeholder="Østerbrogade 21, 2tv" submission={handleChange}/>
                                    <ClaimsInput essential name="By" inputName="City" placeholder="København Ø" submission={handleChange}/>
                                    <ClaimsInput essential name="Postnummer" inputName="Zip" placeholder="2100" submission={handleChange}/>
                                    <ClaimsInput name="Hvor fandt hændelsen sted?" inputName="Home" placeholder="Brug hjemmeadresse" type="slider" toggle={(e) => toggleState("homeAddresse", e)}/>
                                    <div className={!states.homeAddresse?"":"d-none"}>
                                        <Header size="h3" className="my-4 mt-5 pt-5">Hændelses Adresse</Header>
                                        <ClaimsInput essential name="Adresse for hændelsen" inputName="IncidentAddress" placeholder="Østerbrogade 21, 2tv" submission={handleChange}/>
                                        <ClaimsInput essential name="By for hændelsen" inputName="IncidentCity" placeholder="København Ø" submission={handleChange}/>
                                        <ClaimsInput essential name="Postnummer for hændelsen" inputName="IncidentZip" placeholder="2100" submission={handleChange}/>
                                    </div>

                                    <Header size="h3" className="my-4 mt-5 pt-5">Skade Information</Header>

                                    <ClaimsInput essential name="Fortæl kort om hændelsen" inputName="Explanation" placeholder="Tekst..." type="textarea" submission={handleChange}/>
                                    <ClaimsInput essential name="Model og mærke" inputName="Model" placeholder="Fx. MacBook Air 13 M1/8/256 2020 (space grey) Apple" submission={handleChange}/>
                                    <ClaimsInput name="Serienummer (hvis tilgængeligt)" inputName="SN" placeholder="Fx. W88401231AX eller C02CG123DC79" submission={handleChange}/>
                                    <ClaimsInput name="Er produktet blevet stjålet?" inputName="Theft" placeholder="Nej eller ja" type="slider" submission={handleChange}/>
                                    <ClaimsInput essential name="Hvornår skete det?" inputName="TimeOfIncident" placeholder="Tid - DD/MM/ÅÅÅÅ" submission={handleChange}/>
                                    <ClaimsInput essential name="Policenummer" inputName="PolicyNumber" placeholder="XXXX-XXXX-XXXX" submission={handleChange}/> 
                                    <ClaimsInput name="Hvilke andre relevante forsikringer har du?" inputName="OtherInsurance" placeholder="Fx. Indbo og ulykkesforsikring." submission={handleChange}/>
                                    <ClaimsInput name="Upload billede(r) eller video af det beskadige produkt (hvis muligt)." inputName="FileProduct" placeholder="Click to choose file - No file chosen" type="file" multiple={true} submission={handleChange}/>
                                    
                                    <ClaimsInput name="Mistet kvittering?" inputName="Receipt" placeholder="Nej eller ja" type="slider" toggle={(e) => toggleState("receiptOpen", e)}/>
                                    <div className={!states.receiptOpen?"":"d-none"}>
                                        <ClaimsInput name="Upload kvittering for produktet*" inputName="FileReceipt" placeholder="Click to choose file - No file chosen" type="file" submission={handleChange}/>
                                    </div>
                                    
                                    <div className={states.receiptOpen?"":"d-none"}>
                                        <Header size="h3" className="my-4 mt-5 pt-5">Ved mistet kvittering</Header>
                                        <ClaimsInput essential name="Prisen på produktet" inputName="ProductPrice" placeholder="Fx. 2999" submission={handleChange}/>
                                        <ClaimsInput essential name="Hvor er produktet købt?" inputName="ProductPlaceOfPurchase" placeholder="Firmanavn eller link til hjemmeside" submission={handleChange}/>
                                        <ClaimsInput essential name="Hvornår er produktet købt?" inputName="ProductTimeOfPurchase" placeholder="Tid - DD/MM/ÅÅÅÅ" submission={handleChange}/>
                                        <ClaimsInput essential name="Beskrivelse af produktet" inputName="ProductDescription" placeholder="Tekst..." type="textarea" submission={handleChange}/>
                                    </div>

                                    <Button disabled={states.isSubmitted}>Anmeld skade</Button><p className="mt-3 px-md-5 d-inline-block">{ states.status }</p>

                                </form>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default ClaimsForm;