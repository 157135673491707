import SectionWrapper from "../SectionWrapper";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../Header";
import Paragraph from "../Paragraph";
import { LazyLoadImage } from "react-lazy-load-image-component";
import QR from "../../img/qr.png";
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import './AppSection.css';
import iPhone1 from "../../img/iPhone1.png";
import iPhone2 from "../../img/iPhone2.png";
import MaxWidthWrap from "../MaxWidthWrap";

const AppSection = () => {
  return ( 
    <>
      {/* <SectionWrapper className="justify-content-center d-none d-xl-flex"> */}
      <Container className="custom-container d-none d-xl-block py-4 my-7">
        <Row className="pt-6">
          <Col xl={6}>
            <Header className='mb-4 fw-light' size='h4' color='primary'>

              {/* The Frankly Insure App */}
              Frankly Insure App


            </Header>
            <Header className='mb-4 fw-bold' size='h4' color='primary'>

              {/* Available soon. */}
              Tilgængelig snart.

            </Header>
            <MaxWidthWrap width={516}>
              <Paragraph size='p3' className='mb-4 fw-light'>

                {/* Browse your insurances, track your claim status, and never argue with an insurance agent again. */}
                Saml dine forsikringer, følg med i din <br/> skadesproces, og modtag skarpe tilbud. 


              </Paragraph>
            </MaxWidthWrap>
            {/* <div>
              <Paragraph size='p2' className='mb-1 paragraph-muted fw-normal'>
                Scan QR-Code to get started.
              </Paragraph>
              <LazyLoadImage
                width="156"
                height="156"
                alt="BILLEDE"
                src={QR}
                effect="black-and-white"
                className='d-block'
              />
            </div> */}
          </Col>
          <Col xl={6} className="d-none d-xl-flex justify-content-end">
            <div className='app-preview'>
              <LazyLoadImage
                alt="BILLEDE"
                src={iPhone1}
                effect="black-and-white"
                width={290}
                className='iphone1'
              />
              <LazyLoadImage
                alt="BILLEDE"
                src={iPhone2}
                effect="black-and-white"
                width={290}
                className='iphone2'
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
   );
}
 
export default AppSection;