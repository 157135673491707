import './Steps.css';
import SectionWrapper from '../SectionWrapper';
import Paragraph from '../Paragraph';
import Header from '../Header';
import { Row, Col, Container } from 'react-bootstrap';
import StepCard from '../StepCard';
import VectorLogo from '../../img/VectorLogo.svg';
import Bicycle from '../../img/Bicycle.svg';

import Step1 from '../../img/Step1.svg';
import Step2 from '../../img/Step2.svg';
import Step3 from '../../img/Step3.svg';
import MaxWidthWrap from '../MaxWidthWrap';
import Toggle from '../../img/Toggle.svg';
import NavLogo from '../../img/NavLogo.svg';

const Steps = () => {
  return ( 
    <Container className='custom-container pt-5 pt-xl-5 mt-xl-7 long'>
      <Row className='position-static'>
        <Col xl={6} className=''>
          <div className="sticky-left d-flex text-center text-xl-start justify-content-xl-start justify-content-center">
            <div className="d-block">
              <Header size="h4" color="primary" className="fw-light mb-4">
                {/* <span className="fw-bold">Sometimes </span> <br className='d-none d-sm-block'/> you have to do <br/> all the work. */}
                <span className="fw-bold">Nogle gange </span> <br className='d-none d-sm-block'/> skal du lave alt <br/> arbejdet.
              </Header>
              <Paragraph size="p3" className="fw-normal">
                {/* And sometimes you don't.  */}
                Andre gange skal du ikke.
              </Paragraph>
            </div>
          </div>
        </Col>
        <Col xl={6} className='step-card-col'>
          <div className="d-flex flex-column">
            <div className="mx-auto ms-xl-auto me-xl-0">
            {/* <StepCard  className="sticky-card" bottom='60px' number="1" description={<>Leave us your contact <br/>details</>}> */}
            <StepCard  className="sticky-card" bottom='60px' number="1" description={<>Indtast kontaktoplysninger</>}>
              <img style={{marginTop: "-30px"}} className='step-card-image w-100' src={Step1} alt="Step 1"/>
            </StepCard>
            {/* <StepCard  className="sticky-card" bottom='86px' number="2" description="We will get the integration ready for you"> */}
            <StepCard  className="sticky-card" bottom='86px' number="2" description={<>Vi klargører integrationen efter<br/> dine anvisninger</>}>
              <img className='step-card-image w-100' src={Step2} alt="Step 2"/>
            </StepCard>
            {/* <StepCard className="sticky-card bell" bottom='60px' number="3" description="Our technicians will get you live within 1-2 hours"> */}
            <StepCard className="sticky-card bell" bottom='60px' number="3" description="Vores teknikere bruger 1-2 timer på at klargøre løsningen">
                <img style={{height: "120px"}} className='step-card-image' src={Step3} alt="Step 3"/>
            </StepCard>
            {/* <StepCard className="sticky-card" bottom='40px' number="4" description="That's it. Shoppers can add protection at checkout"> */}
            <StepCard className="sticky-card" bottom='40px' number="4" description="Sådan! Dine kunder kan nu tilføje forsikring ved checkout">
              <img style={{marginTop: "-10px"}} className='step-card-image' src={Bicycle} alt="Step 4"/>
              <div className='product-info'>
                <Header fontSize="16px" color="primary" className="hero-header mb-2">
                  MAKO HYBRID HP 6
                </Header>
                <Header size="h3" color="primary" className="hero-header mb-3">
                  27.500 kr.
                </Header>
                <div className='frankly-info py-2 px-2 d-flex justify-content-between align-items-center w-100'>
                  <div className="d-inline-flex align-items-center">
                    <img src={Toggle} alt="Toggle" className='toggle-icon m-0 p-0' />
                    <Paragraph fontSize='11px' className='paragraph-muted bold ps-2'>
                      {/* All Risk Coverage */}
                      All Risk Forsikring
                    </Paragraph>
                  </div>
                  <div className="d-inline-flex align-items-center">
                    <Paragraph fontSize='12px' className='paragraph-muted bold'>
                      {/* €2.99 */}
                      DKK 21
                    </Paragraph>
                    <Paragraph fontSize='10px' className='paragraph-muted pe-2'>
                      {/* /Monthly */}
                      /md.
                    </Paragraph>
                  </div>

                </div>
              </div>
            </StepCard>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
   );
}
 
export default Steps;