import SectionWrapper from "../SectionWrapper";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import './Flow.css';

import { Container, Row, Col } from "react-bootstrap";
import Header from "../Header";
import A9 from "../../img/A9.png";
import AddToCart from "../../img/AddToCartDK.svg";
import Boc from "../../img/Boc.png";
import Protection from "../../img/AddProtectionDK.svg";
import Checkout from "../../img/CheckoutDK.svg";
import Paragraph from "../Paragraph";
import MaxWidthWrap from "../MaxWidthWrap";

const Flow = () => {
  return ( 
    <Container className="custom-container pt-3 mt-7">
      <Row className="d-none d-xl-block">
        <Col>
          <Header size='h4' color="primary" className='collage-header fw-light pt-5 pb-4'>

            {/* One-click <span className="fw-bold">insurance</span> */}
            <span className="fw-bold">Forsikret</span> med ét klik

          </Header>
          <MaxWidthWrap center width={540}>
            <Paragraph className='text-center d-flex justify-content-center pb-4 fw-light' size='p3'>

              {/* Shop like normal, add protection at checkout and stay worry free with Frankly Insure.  */}
              Shop som du plejer og tilføj tryghed ved checkout. <br/>Så simpelt er det.  

            </Paragraph>  
          </MaxWidthWrap>
          <div className='flow-row pt-4'>
            <div className="flow-col  d-flex flex-column align-items-center">
              <Header size='h3' color="primary" className='flow-header fw-normal pb-5 mt-4'>

                {/* Shop like normal */}
                Shop

              </Header>
              <LazyLoadImage
                alt="BILLEDE"
                src={A9}
                effect="black-and-white"
                className='a9'
                width={186}
                />
              <div className="image-wrapper mt-0 px-3">
                <LazyLoadImage
                  alt="BILLEDE"
                  src={AddToCart}
                  effect="black-and-white"
                  className='w-100 add-to-cart'
                />
              </div>
            </div>
            <div className="flow-col middle d-flex flex-column align-items-center">
              <Header size='h3' color="primary" className='flow-header fw-normal pb-2 mt-4 mb-1'>

                {/* Add protection */}
                Tilføj forsikring

              </Header>
              <span className="">
                <LazyLoadImage
                  alt="BILLEDE"
                  src={Protection}
                  effect="black-and-white"
                  className='flow-protection'
                  width={300}
                  />
              </span>
            </div>
            <div className="flow-col d-flex flex-column align-items-center">
              <Header size='h3' color="primary" className='flow-header fw-normal pb-2 mt-4'>

                {/* Check out with Frankly */}
                Checkout

              </Header>
              <span className="flow-checkout">
                <LazyLoadImage
                  alt="BILLEDE"
                  src={Checkout}
                  effect="black-and-white"
                  className=''
                  width={300}
                  />
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="d-block d-xl-none position-static flow-mobile-row">
        <Col>
          <Header size='h4' color="primary" className='collage-header fw-normal pt-5 pb-4'>

            {/* One-click <span className="fw-bold">insurance</span> */}
            <span className="fw-bold">Forsikret</span> med ét klik

          </Header>
          <MaxWidthWrap center width={540}>
            <Paragraph className='mb-5 text-center paragraph-muted d-flex justify-content-center pb-4 fw-normal' size='p3'>

              {/* Shop like normal, add protection at checkout and stay worry free with Frankly Insure.  */}
              Shop som du plejer og tilføj tryghed ved checkout. <br/>Så simpelt er det.              
            
            </Paragraph>  
          </MaxWidthWrap>
          <div className='mt-6 p-0'>
            <div className="mx-auto flow-col flow-sticky d-flex flex-column align-items-center">
              <Header fontSize='24px' color="primary" className='flow-header fw-medium pb-5 mt-4'>
                
                {/* Shop like normal */}
                Shop

              </Header>
              <LazyLoadImage
                alt="BILLEDE"
                src={A9}
                effect="black-and-white"
                className='a9'
                width={186}
                />
              <div className="image-wrapper mt-0 px-3">
                <LazyLoadImage
                  alt="BILLEDE"
                  src={AddToCart}
                  effect="black-and-white"
                  className='w-100 add-to-cart'
                />
              </div>
            </div>
            <div className="mx-auto flow-col flow-sticky d-flex flex-column align-items-center">
              <Header fontSize='24px' color="primary" className='flow-header fw-medium pb-3 mt-4'>
                
                {/* Add protection */}
                Tilføj forsikring
              
              </Header>
              <span className="mt-4">
                <LazyLoadImage
                  alt="BILLEDE"
                  src={Protection}
                  effect="black-and-white"
                  className=''
                  width={300}
                  />
              </span>
            </div>
            <div className="mx-auto flow-col flow-sticky d-flex flex-column align-items-center">
              <Header fontSize='24px' color="primary" className='flow-header fw-medium pb-4 mt-4'>
                
                {/* Check out with Frankly */}
                Checkout

              </Header>
              <span className="mt-3">
                <LazyLoadImage
                  alt="BILLEDE"
                  src={Checkout}
                  effect="black-and-white"
                  className=''
                  width={300}
                  />
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
   );
}
 
export default Flow;