import { LazyLoadImage } from "react-lazy-load-image-component";
import { Col, Container, Row } from "react-bootstrap";

import "./ClaimsHero.css";

import ClaimsImg from "../../../img/ClaimsImage.png";
import MaxWidthWrap from "../../MaxWidthWrap";
import Header from "../../Header";
import Paragraph from "../../Paragraph";

const ClaimsHero = () => {
    return (
        <Container className="my-md-5 my-2 custom-container">
            <Row className="pt-3">
                <Col lg={6}>
                    <MaxWidthWrap width={561} className="m-auto">
                        <Header size="h5" color="primary" className="hero-header header-component fw-light mb-5 mt-5">
                            <span className="fw-bold">
                                Vi står altid klar <br/>
                            </span>
                            til at hjælpe
                        </Header>
                        <Paragraph size="p3" className="fw-light">
                            Vi har gjort det super let at melde en skade og du<br/> kan gøre det 24/7. Udfyld formularen og vi vil<br/> behandle sagen hurtigst muligt.
                        </Paragraph>
                    </MaxWidthWrap>
                </Col>
                <Col lg={6} className="d-flex justify-content-center justify-content-lg-right">
                    <div className="d-table claims-hero-img-wrap">
                        <LazyLoadImage
                            alt="Claims Hero Image"
                            src={ClaimsImg}
                            effect="black-and-white"
                            className="claims-hero-img"
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default ClaimsHero;